import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import ScrollableDropShadow from '../../../../../src/ui/ScrollableDropShadow.jsx'
import SearchResults from '../../../../../src/ui/SearchResults/SearchResults.js'
import RouteUnavailableOptionsWidget from '../RouteUnavailableOptionsWidget.js'

const SearchResultsStyled = styled(SearchResults)`
  overflow: auto;
`

const getDefaultSearch = state => state.defaultSearch

const getRouteUnavailable = state => state.isRouteUnavailable
const getEndpointIds = state => state.endpointIds
const getPoisToAvoid = state => state.poisToAvoid

const getPlacesNearby = state => state.searchInputs?.[state.currentInputIndex]?.placesNearby

const DirectionsSearchView = ({
  handlePoiClicked,
  handleChooseMapLocationClicked,
  widgetState,
  isDesktop,
  handleEscapeKey,
  T
}) => {
  const [defaultSearch, setDefaultSearch] = useState(getDefaultSearch(widgetState.getState()))
  const [isRouteUnavailable, setIsRouteUnavailable] = useState(getRouteUnavailable(widgetState.getState()))
  const [placesNearby, setPlacesNearby] = useState(getPlacesNearby(widgetState.getState()))
  const [endpointIds] = useState(getEndpointIds(widgetState.getState()))
  const [poisToAvoid] = useState(getPoisToAvoid(widgetState.getState()))

  useEffect(() => widgetState.addCallback(state => {
    setDefaultSearch(getDefaultSearch(state))
    setIsRouteUnavailable(getRouteUnavailable(state))
    setPlacesNearby(getPlacesNearby(state))
  }))

  return isRouteUnavailable
    ? (
      <RouteUnavailableOptionsWidget T={T} endpointIds={endpointIds} poisToAvoid={poisToAvoid}/>
    )
    : (
      <ScrollableDropShadow T={T} isDesktop={isDesktop()}>
        <SearchResultsStyled
          defaultSearch={defaultSearch}
          placesNearby={placesNearby}
          onChooseMapLocationClicked={handleChooseMapLocationClicked}
          onPoiClicked={handlePoiClicked}
          isDesktop={isDesktop()}
          handleEscapeKey={handleEscapeKey}
          T={T}
        />
      </ScrollableDropShadow>
    )
}

export default DirectionsSearchView
