import * as R from 'ramda'

import { distance as getGeoDistance, getBearing } from '../../../src/utils/geodesy.js'

const TIME_BETWEEN_ANIMATED_LINES = 3.75

/**
 * @param {number} timeOffsetMs - time the animation is running, in milliseconds
 * @param {AnimatedNavLineSection[]} rawAnimatedNavLineSections
 * @param {number} timeBetweenLinesSec - time in seconds between animated lines starts
 * @return {Array} list of GeoJson animated navigation line features
 */
// todo check if we can use 'along' from Turf
export const animatedNavLineFeaturesAtTime = (
  timeOffsetMs,
  rawAnimatedNavLineSections,
  timeBetweenLinesSec = TIME_BETWEEN_ANIMATED_LINES
) => {
  const features = []
  let time = (timeOffsetMs / 1000) % timeBetweenLinesSec
  const endTime = R.last(rawAnimatedNavLineSections).endTime
  while (time < endTime) {
    const section = findSectionAtTime(time, rawAnimatedNavLineSections)
    const points = getPolylineForTime(section, time)
    const feature = createNavSectionFeature(points, section.ordinal)
    features.push(feature)
    time += timeBetweenLinesSec
  }
  return features
}

const findSectionAtTime = (time, rawAnimatedNavLineSections) => rawAnimatedNavLineSections
  .find(({ startTime, endTime }) => startTime <= time && time <= endTime)

const createNavSectionFeature = (points, ordinal) => ({
  type: 'Feature',
  geometry: {
    type: 'LineString',
    coordinates: points.map(R.prop('coordinate'))
  },
  properties: {
    category: 'nav.animatedline',
    ordinal
  }
})

const getPolylineForTime = (section, time) => {
  const startTime = section.startTime
  const endTime = section.endTime
  const sectionDistance = R.last(section.points).distance

  const halfTime = (endTime + startTime) / 2

  if (time < halfTime) {
    const normalizedTime = (time - startTime) / (halfTime - startTime)
    const easeIn = normalizedTime * normalizedTime
    const distance = Math.max(0.0, easeIn * sectionDistance)
    const endIndex = pointIndexAtDistance(distance, section.points)
    return [...section.points.slice(0, endIndex), interpolatedPointAtIndex(section.points, endIndex, distance)]
  } else {
    const normalizedTime = 1 - (time - halfTime) / (endTime - halfTime)
    const easeOut = 1 - normalizedTime * normalizedTime
    const distance = Math.min(sectionDistance, sectionDistance * easeOut)
    const startIndex = pointIndexAtDistance(distance, section.points)
    return [interpolatedPointAtIndex(section.points, startIndex, distance), ...section.points.slice(startIndex)]
  }
}

const interpolatedPointAtIndex = (points, index, distance) => {
  const startPoint = points[index - 1]
  const endPoint = points[index]
  const t = (distance - startPoint?.distance) / (endPoint?.distance - startPoint?.distance)
  const lng = startPoint?.coordinate[0] + t * (endPoint?.coordinate[0] - startPoint?.coordinate[0])
  const lat = startPoint?.coordinate[1] + t * (endPoint?.coordinate[1] - startPoint?.coordinate[1])
  return { coordinate: [lng, lat], distance }
}

const pointIndexAtDistance = (distance, points) => {
  return points.findIndex((p, i) => i > 0 && p.distance >= distance)
}

/**
 * @typedef AnimatedNavLineSection
 * @property {Point[]} points
 * @property {number} startTime,
 * @property {number} endTime,
 * @property {number} ordinal
 *
 * @typedef Point
 * @property {number} distance - distance to start of section
 * @property {number[]} coordinate - coordinate pairs [longitude, latitude]
 *
 * @typedef Segment
 * @property {string} levelId
 * @property {string} ordinalId
 * @property {number[][]} coordinates - list of coordinate pairs [longitude, latitude]
 * @property {string} segmentType
 * @property {Badge[]} badges
 * @property {boolean} shouldDrawSegment
 *
 * @typedef Badge
 * @property {string} canonicalName
 * @property {number[]} coordinates - coordinate pair [longitude, latitude]
 *
 * @param {Segment[][]} segments
 * @return {AnimatedNavLineSection[]}
 */
export const prepareRawAnimatedNavlineSections = segments => {
  const sections = []

  segments.forEach(segmentGroup => {
    let currentFloorOrdinal = null
    let time = 0
    let currentSection = []
    const groupSections = []

    const filteredSegmentGroup = segmentGroup.filter(segment => segment.coordinates.length > 1)

    const addNewSection = () => {
      const section = initSection(currentFloorOrdinal, currentSection, time)
      time = section.endTime
      groupSections.push(section)
      currentSection = []
    }

    filteredSegmentGroup.forEach(segment => {
      const segmentOrdinal = Number(segment.ordinalId.split(': ')[1])
      if (!R.isNil(currentFloorOrdinal) && segmentOrdinal !== currentFloorOrdinal) {
        addNewSection()
      } else if (currentSection.length && R.last(currentSection) === R.head(segment.coordinates)) {
        addNewSection()
      }
      currentFloorOrdinal = segmentOrdinal
      segment.coordinates.forEach(coordinate => {
        const lastCoordinate = R.last(currentSection)
        if (currentSection.length && R.equals(lastCoordinate, coordinate))
          ;
        else if (currentSection.length < 2)
          currentSection.push(coordinate)
        else {
          const beforeLastCoordinate = currentSection[currentSection.length - 2]
          const bearing1 = getBearing(lastCoordinate[1], lastCoordinate[0], coordinate[1], coordinate[0])
          const bearing2 = getBearing(beforeLastCoordinate[1], beforeLastCoordinate[0], lastCoordinate[1], lastCoordinate[0])
          const angle = Math.abs(bearing1 - bearing2)
          if (angle > 45 && angle < 315) {
            addNewSection()
            currentSection.push(lastCoordinate)
          }
          currentSection.push(coordinate)
        }
      })
    })

    if (currentSection.length > 1)
      addNewSection()

    sections.push(groupSections)
  })

  return sections
}

const initSection = (ordinal, coordinates, startTime) => {
  let distance = 0
  const points = []
  // todo use R.mapAccum
  for (let i = 0; i < coordinates.length; i++) {
    const prev = i && coordinates[i - 1]
    const coordinate = coordinates[i]
    const distanceToPrev = prev && getGeoDistance(...coordinate, ...prev)
    distance += distanceToPrev
    const point = {
      distance,
      coordinate
    }
    points.push(point)
  }

  const time = Math.max(0.5, Math.min(2, Math.sqrt(distance) / 4))
  const endTime = startTime + time

  return {
    points,
    startTime,
    endTime,
    ordinal
  }
}
