import * as R from 'ramda'
import React from 'react'
import styled from 'styled-components'

import { Icon } from '../../../../src/ui/icons/Icon.jsx'

import { PrimaryLabel, SecondaryLabel } from './styles.js'

const Wrapper = styled.div`
  text-align: center;

  .-mobile & {
    padding: 32px 56px;
  }

  .-desktop & {
    padding: 16px 28px;
  }
`

const RouteUnavailableOptionsWidget = ({ className, T, endpointIds, poisToAvoid }) => {
  const poiIdsToAvoid = poisToAvoid.map(poi => poi.poiId)
  const affectedByDynamicRouting = R.intersection(poiIdsToAvoid, endpointIds)
  const plural = affectedByDynamicRouting.length > 1

  const affectedNames = affectedByDynamicRouting.map((poiId, index) => {
    const and = affectedByDynamicRouting.length > 1 && (index === affectedByDynamicRouting.length - 1)
    const poiToAvoid = R.find(obj => obj.poiId === poiId)(poisToAvoid)
    return `${and ? ` ${T('getDirectionsFromTo:and')}` : ''} ${poiToAvoid.name}`
  })

  return affectedByDynamicRouting.length > 0
    ? (
      <Wrapper data-cy='DynamicRoutingInfo' className={className}>
        <Icon className="icon" width={47} height={63} id="route-unavailable" />
        <PrimaryLabel>{T('getDirectionsFromTo:We are sorry')}</PrimaryLabel>
        <SecondaryLabel>
          {plural
            ? T('getDirectionsFromTo:This route is not possible because _stopNames_ are currently unavailable', { stopNames: [affectedNames] })
            : T('getDirectionsFromTo:This route is not possible because _stopName_ is currently unavailable', { stopName: [affectedNames] })
          }
        </SecondaryLabel>

      </Wrapper>
    )
    : (
      <Wrapper data-cy='RouteUnavailableWidget' className={className}>
        <Icon className="icon" width={47} height={63} id="route-unavailable" />
        <PrimaryLabel>{T('getDirectionsFromTo:Route Unavailable')}</PrimaryLabel>
        <SecondaryLabel>
          {T('getDirectionsFromTo:Sorry, we couldn’t find a navigation path because the from or to point is not accessible')}
        </SecondaryLabel>
      </Wrapper>
    )
}

export default RouteUnavailableOptionsWidget
