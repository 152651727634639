import * as R from 'ramda'
import React from 'react'

import { findBoundsOfSegments } from '../../../../src/utils/bounds.js'
import { getBearing } from '../../../../src/utils/geodesy.js'
import { widgetEnum } from '../../../../utils/constants.js'
import { SecurityLaneType as PickerSteps } from '../../../wayfinder/src/wayfinder.js'

import { DIRECTIONS_RESULT_CONTROLS_ID, DIRECTIONS_RESULT_VIEW_ID_DESKTOP, DIRECTIONS_RESULT_VIEW_ID_MOBILE, DIRECTIONS_SEARCH_VIEW_ID, LANE_PICKER_VIEW_ID, RouteOptionsClassification, RouteType, SHOW_DIRECTIONS_FROM_TO_CALLBACK_ID } from './constants.js'
import DirectionsResult from './DirectionsResult/DirectionsResult.js'
import LanePickerView from './LanePickerView/LanePickerView.jsx'
import { checkRoutePath, getDirectAlternateRoutes, allEndPointsDefined, leavingSecurityWarning } from './poiListingUtils.js'
import { getDefaultState, setDefaultRouteAccessibilityChoice } from './widgetState.js'

export const getBearingBetweenEndpoints = (endpoints) => {
  if (!endpoints || !endpoints[0] || !endpoints[1]) return 0 // point north

  const latlngs = endpoints.flatMap(ep => [ep.lat, ep.lng])

  return getBearing(...latlngs)
}

export default function (app, config, widgetState) {
  const log = app.log.sublog('showDirectionsFromTo')
  const { Loading } = app.themePack

  const ANIMATION_CENTER_RADIUS = {
    top: 119 + 50, // top widget height + extra margin to fit the start marker
    bottom: 130 + 14, // bottom widget height + extra margin
    left: 20,
    right: 20
  }

  const showDirectionsFromTo = async ({ from, to, endpointIds = [], requiresAccessibility, doNotMoveMap = false, referrer, selectedSecurityLanes }) => {
    if (from && to) endpointIds = [from, to] // TODO Figure out what's calling this with single point syntax and convert it to multi-point and remove "from" & "to" parameter options.

    const [poisToAvoid] = await app.bus.send('dynamicRouting/poisToAvoid')
    widgetState.update({ poisToAvoid })

    const { endpoints, wereLanesPicked } = widgetState.getState()

    if (requiresAccessibility === undefined)
      requiresAccessibility = widgetState.getState().routeAccessibiltyChoice === RouteType.ACCESSIBLE

    widgetState.update({ endpointIds })

    if (referrer !== undefined)
      widgetState.update({ referrer }) // only overwrite the current referrer if it is specifed explicitly

    app.bus.send('searchResults/showPOIs', { pois: [] }) // clear search results
    app.bus.send('map/cleanMap') // clear map from previous navigation and search results

    setSearchInputsFromEndpointsIds()

    const isDesktop = app.env.isDesktop()

    let navEndpoints
    try {
      navEndpoints = await Promise.all(endpointIds.map(async id => await app.bus.get('wayfinder/getNavigationEndpoint', { ep: id })))

      app.bus.send('history/register', {
        viewId: isDesktop ? DIRECTIONS_RESULT_VIEW_ID_DESKTOP : DIRECTIONS_RESULT_VIEW_ID_MOBILE,
        event: 'navigation/showDirectionsFromTo',
        params: { endpointIds, requiresAccessibility, doNotMoveMap, selectedSecurityLanes }
      })
    } catch (error) {
      log.error(error)
      return showUnavailableRouteInfo()
    }
    if (navEndpoints.length === 0) return showUnavailableRouteInfo()

    const wasRoutePreviouslyDisplayed = wereLanesPicked && R.equals(endpoints, navEndpoints)

    const routeAccessibiltyChoice = requiresAccessibility ? RouteType.ACCESSIBLE : RouteType.DIRECT // if DIRECT, user chose direct OR direct was already accessible
    setDefaultRouteAccessibilityChoice(routeAccessibiltyChoice)

    widgetState.update({ endpoints: navEndpoints, routeAccessibiltyChoice })

    displayDirections({ endpoints: navEndpoints, doNotMoveMap, wasRoutePreviouslyDisplayed, selectedSecurityLanes })
  }

  app.bus.on('navigation/showDirectionsFromTo', showDirectionsFromTo)

  const getInputNewData = async endpointId => {
    if (!endpointId)
      return {}
    if (isLocation(endpointId)) {
      return { location: endpointId, term: endpointId.title }
    } else if (typeof endpointId === 'string' && endpointId.includes(',')) { // if there was no ID passed but location
      const location = await app.bus.get('wayfinder/getNavigationEndpoint', { ep: endpointId })
      return { location, term: location.title }
    } else if (typeof endpointId === 'number' || typeof endpointId === 'string') {
      const poi = await app.bus.get('poi/getById', { id: endpointId }).catch(error => console.error(error))
      if (poi)
        return { chosenPoi: poi, term: poi.name }
    }
    return {}
  }

  const setSearchInputsFromEndpointsIds = async () => {
    const { endpointIds } = widgetState.getState()

    const updatedSearchInputs = await Promise.all(endpointIds.map(async id => await getInputNewData(id)))

    widgetState.update({ searchInputs: updatedSearchInputs }, { noTrigger: [SHOW_DIRECTIONS_FROM_TO_CALLBACK_ID] })
  }

  const displayDirections = async ({
    endpoints,
    doNotMoveMap = false,
    wasRoutePreviouslyDisplayed = false,
    selectedSecurityLanes
  }) => {
    widgetState.update({ wasRoutePreviouslyDisplayed, routeTypes: RouteOptionsClassification.DISTINCT })
    await displayDirectionsFromToEndpoint({ endpoints, doNotMoveMap, selectedSecurityLanes })
    widgetState.update({ hasLoaded: true })
  }

  /**
   * Performs the setup, obtains the route, displays markers, displays navlines, displays steps, centers map, etc.
   */
  const displayDirectionsFromToEndpoint = async ({ endpoints, doNotMoveMap, selectedSecurityLanes }) => {
    app.bus.send('layers/show', { id: DIRECTIONS_RESULT_CONTROLS_ID })
    app.bus.send('layers/hideMultiple', [DIRECTIONS_SEARCH_VIEW_ID, DIRECTIONS_RESULT_VIEW_ID_DESKTOP, DIRECTIONS_RESULT_VIEW_ID_MOBILE])

    const { routeExists, hasSecurity, hasImmigration } = await checkRoutePath(app.bus, endpoints)
    const leavingSecurity = await leavingSecurityWarning(app.bus, endpoints)
    widgetState.update({ leavingSecurity })

    if (!routeExists)
      return showUnavailableRouteInfo()

    if ((hasSecurity || hasImmigration) && !selectedSecurityLanes && !doNotMoveMap)
      displayLanePickerModal(hasSecurity, hasImmigration)
    else {
      try {
        const options = selectedSecurityLanes ? { selectedSecurityLanes } : { }
        const { directRoutes, alternativeRoutes } = await getDirectAlternateRoutes(app.bus, endpoints, options)

        return displayRoutes(directRoutes, alternativeRoutes, doNotMoveMap)
      } catch (errors) {
        log.error(errors)
        return showUnavailableRouteInfo()
      }
    }
  }

  const displayLanePickerModal = async (hasSecurity, hasImmigration) => {
    const { wasRoutePreviouslyDisplayed } = widgetState.getState()
    if (wasRoutePreviouslyDisplayed) {
      submitLanePickerModal()
    } else {
      let securityLanes = widgetState.getState().securityLanes
      let immigrationLanes = widgetState.getState().immigrationLanes
      if (!securityLanes && !immigrationLanes) { // apparently first time here.. Welcome to the LanePicker - Lets set you up!
        const queueTypes = await app.bus.get('venueData/getQueueTypes')
        securityLanes = hasSecurity ? queueTypes.SecurityLane : []
        immigrationLanes = hasImmigration ? queueTypes.ImmigrationLane : []
        if (!securityLanes)
          throw Error('Unknown queue types for security or immigration')
        widgetState.update({ securityLanes, immigrationLanes })
      }
      const lanePickerStep = securityLanes.length ? PickerSteps.SECURITY : PickerSteps.IMMIGRATION
      widgetState.update({ lanePickerStep, wereLanesPicked: false })
      app.bus.send('layers/show', { id: LANE_PICKER_VIEW_ID })
    }
  }

  const submitLanePickerModal = async () => {
    const { endpoints, securityLanes, immigrationLanes } = widgetState.getState()
    app.bus.send('layers/hide', { id: LANE_PICKER_VIEW_ID })

    widgetState.update({ wereLanesPicked: true })

    const isNotEmpty = R.complement(R.isEmpty)
    const findDefault = R.pipe(R.filter(R.prop('default')), R.map(R.prop('id')))
    const selectedSecurityLanes = R.filter(isNotEmpty, {
      [PickerSteps.SECURITY]: findDefault(securityLanes),
      [PickerSteps.IMMIGRATION]: findDefault(immigrationLanes)
    })
    // This will grab the last clear text element which is the proper place
    // to put tab focus after user closes the modal due to WCAG
    const element = Array.from(document.querySelectorAll('.closeButton')).slice(-1)[0]
    if (element) {
      element.focus()
    }

    try {
      const { directRoutes, alternativeRoutes } = await getDirectAlternateRoutes(app.bus, endpoints, { selectedSecurityLanes })
      if (directRoutes.length === 0 || directRoutes.some(route => !doesRouteExist(route)))
        return showUnavailableRouteInfo()
      else
        return displayRoutes(directRoutes, alternativeRoutes)
    } catch (error) {
      log.error(error)
      return showUnavailableRouteInfo()
    }
  }

  const doesRouteExist = R.path(['steps', 'length'])

  const showUnavailableRouteInfo = async () => {
    await app.bus.send('navigation/show', { reset: false })
    app.bus.send('layers/hideMultiple', [DIRECTIONS_RESULT_VIEW_ID_DESKTOP, DIRECTIONS_RESULT_VIEW_ID_MOBILE])
    widgetState.update({ isRouteUnavailable: true, wereLanesPicked: false })
  }

  // Note: accessibleRoutes may be null if no accessible route exists
  const displayRoutes = async (directRoutes, accessibleRoutes, doNotMoveMap) => {
    const { routeAccessibiltyChoice, endpointIds, referrer } = widgetState.getState()

    app.bus.send('layers/show', { id: DIRECTIONS_RESULT_VIEW_ID_DESKTOP })
    app.bus.send('layers/show', { id: DIRECTIONS_RESULT_VIEW_ID_MOBILE })

    const currentRoute = (routeAccessibiltyChoice === RouteType.DIRECT || !accessibleRoutes) ? directRoutes : accessibleRoutes
    const { times, distances, steps, segments } = parseRoute(currentRoute)

    widgetState.update({ times, distances, steps, segments, currentStep: 0, directRoutes, accessibleRoutes })

    if (!doNotMoveMap)
      centerMapForNavline(segments[0])

    // If the screen is mobile - hide search view on navigation
    if (app.env.isMobile())
      app.bus.send('layers/hide', { id: DIRECTIONS_SEARCH_VIEW_ID })

    // true if the primary route (solid blue route) is accessbile or not (may or may not be the "direct" route...)
    const isPrimaryRouteAccessible = (routeAccessibiltyChoice === RouteType.ACCESSIBLE) || widgetState.getState().routeTypes === RouteOptionsClassification.DEFAULT_IS_ACCESSIBLE

    const entitiesToHighlight = endpointIds.filter(endpoint => !isLocation(endpoint)) // filter out bluedot locations
    app.bus.send('map/highlightEntities', { ids: entitiesToHighlight })
    compareMultiLegRoutes(directRoutes, accessibleRoutes)

    app.bus.send('event/directions', { endpointIds, routedThroughQueues: directRoutes[0].queues, isPrimaryRouteAccessible, referrer })

    // show a toast depending on the route shown
    displayRouteNotification()
  }

  const displayRouteNotification = () => {
    const { routeTypes, routeAccessibiltyChoice } = widgetState.getState()
    // if the environment is mobile, do not show the toast
    if (!app.env.isDesktop())
      return
    const T = app.gt()
    if (routeTypes === RouteOptionsClassification.DEFAULT_IS_ACCESSIBLE)
      app.bus.send('toast/show', {
        message: T('getDirectionsFromTo:Route is accessible'),
        variant: 'success'
      })
    else if (routeTypes === RouteOptionsClassification.DISTINCT && routeAccessibiltyChoice === RouteType.DIRECT)
      app.bus.send('toast/show', {
        message: T('getDirectionsFromTo:Accessible route available'),
        variant: 'info'
      })
    else if (routeTypes === RouteOptionsClassification.NO_ACCESSIBLE_ROUTES)
      app.bus.send('toast/show', {
        message: T('getDirectionsFromTo:No accessible route available'),
        variant: 'warning'
      })
  }

  const parseRoute = (route) => {
    const mapLegs = prop => route.map(R.prop(prop))
    return {
      times: mapLegs('time'),
      distances: mapLegs('distance'),
      steps: mapLegs('steps'),
      segments: mapLegs('segments')
    }
  }

  const isLocation = endpoint => R.keys(endpoint).length

  // Examine the two routes for accessibility.
  // If the direct route is accessible, set routeType as "defaultRouteAccessible"
  // if the alternative route does not exist or is not accessible, routeTypes becomes "noAccessibleRoute"
  // if either of the above conditions are not true, then its "distinct"
  const compareMultiLegRoutes = (directRoute, alternativeRoute) => {
    const everyStepIsAccesible = route => route.every(leg => leg.steps.every(step => step.isAccessible))

    widgetState.update({ routeTypes: RouteOptionsClassification.DISTINCT })

    // If direct route is accessible, set routeTypes to "defaultRouteAccessible"
    if (everyStepIsAccesible(directRoute))
      widgetState.update({ routeTypes: RouteOptionsClassification.DEFAULT_IS_ACCESSIBLE })

    // If the default route is not accessible and alternative route isn't either, then show
    // "noAccessibleRoute" info
    else if (!(alternativeRoute && alternativeRoute.every(x => x) && everyStepIsAccesible(alternativeRoute)))
      widgetState.update({ routeTypes: RouteOptionsClassification.NO_ACCESSIBLE_ROUTES })
  }

  // Note, accessibleRoute may be null or it may be equal to the direct route
  function displayNavlines (directRoutes, accessibleRoutes, routeAccessibiltyChoice, indexToFocus = 0) {
    app.bus.send('map/resetNavlineFeatures')

    let primaryType = 'multipoint'
    let alternativeType = 'alternativemultipoint'

    directRoutes.forEach((_, index) => {
      if (index === indexToFocus) {
        primaryType = 'primary'
        alternativeType = 'alternative'
      }
      app.bus.send('map/showNavlineFeatures', {
        segments: directRoutes[index].segments,
        category: routeAccessibiltyChoice === RouteType.DIRECT ? primaryType : alternativeType
      })

      if (accessibleRoutes?.at(index))
        app.bus.send('map/showNavlineFeatures', {
          segments: accessibleRoutes[index].segments,
          category: routeAccessibiltyChoice === RouteType.ACCESSIBLE ? primaryType : alternativeType
        })

      primaryType = 'multipoint'
      alternativeType = 'alternativemultipoint'
    })
  }
  const showNavlinesAsync = async () => {
    const { directRoutes, accessibleRoutes, currentStop, routeAccessibiltyChoice, searchInputs } = widgetState.getState()
    if (allEndPointsDefined(searchInputs) && directRoutes) displayNavlines(directRoutes, accessibleRoutes, routeAccessibiltyChoice, currentStop)
    else widgetState.update({ directRoutes: [], accessibleRoutes: [] })
  }
  widgetState.addCallback(showNavlinesAsync)

  const centerMapForNavline = async segments => {
    // get a list of features contained within the starting point's ordinal (we center on those)
    const featuresInStartingPointOrdinal = segments.filter((el) => el.ordinalId === segments[0].ordinalId)

    // if all other steps are on different floor just animate to the first one
    if (featuresInStartingPointOrdinal.length < 2)
      return animateToStep(0)

    // ensure floor will change to show the first step
    const { steps, endpoints } = widgetState.getState()
    app.bus.send('mapLevelSelector/selectLevel', { id: steps[0][0].animationAnchor.floorId })

    const bounds = findBoundsOfSegments(featuresInStartingPointOrdinal)

    app.bus.send('map/centerInBounds', { bounds, pitch: 60, bearing: getBearingBetweenEndpoints(endpoints), animOptions: { padding: ANIMATION_CENTER_RADIUS } })
  }

  const animateToPoint = (latitude, longitude, floorId) => app.bus.send('map/animateToPoint', { lat: latitude, lng: longitude, zoom: 19.5, floorId })

  const animateToStep = async (stepIndex, stopIndex = 0) => {
    const { steps } = widgetState.getState()
    const step = steps[stopIndex][stepIndex]

    if (stepIndex > 0 && stepIndex < steps[stopIndex].length - 1) {
      app.bus.send('map/centerInBounds', { bounds: step.bounds, floorId: step.animationAnchor.floorId, animOptions: { padding: ANIMATION_CENTER_RADIUS } })
    } else {
      animateToPoint(step.animationAnchor.lat, step.animationAnchor.lng, step.animationAnchor.floorId)
    }
  }

  const stepForward = () => {
    const { currentStep, currentStop, steps } = widgetState.getState()
    const newStep = Math.min(currentStep + 1, steps[currentStop].length - 1)

    if (newStep !== currentStep) {
      widgetState.update({ currentStep: newStep })
      animateToStep(newStep, currentStop)
    } else {
      // if we are at the last step in our stop, navigate to the next stop unless we're at the last step in our last stop then do nothing
      const newCurrentStop = Math.min(currentStop + 1, steps.length - 1)
      if (newCurrentStop !== currentStop) {
        widgetState.update({ currentStop: currentStop + 1, currentStep: 0 })
        animateToStep(0, newCurrentStop)
      }
    }
  }

  const stepBackward = () => {
    const { currentStep, currentStop, steps } = widgetState.getState()
    const newStep = Math.max(currentStep - 1, 0)
    if (newStep !== currentStep) {
      widgetState.update({ currentStep: newStep })
      animateToStep(newStep, currentStop)
    } else {
      const newCurrentStop = Math.max(currentStop - 1, 0)
      // if we are at the first step in our stop, navigate to the previous stop unless we're at the first step in our first stop then do nothing
      if (newCurrentStop !== currentStop) {
        widgetState.update({ currentStep: steps[newCurrentStop].length - 1, currentStop: newCurrentStop })
        animateToStep(steps[newCurrentStop].length - 1, newCurrentStop)
      }
    }
  }

  const stepTo = ({ stopIndex, stepIndex }) => {
    widgetState.update({ currentStop: stopIndex, currentStep: stepIndex })
    animateToStep(stepIndex, stopIndex)
  }

  const toggleIsNavigating = () => {
    widgetState.update({ isNavigating: !widgetState.getState().isNavigating })
  }

  const toggleNavigationHeader = (toggleState) => {
    app.bus.send('screen/displayHeader', { display: toggleState })
  }

  // For multipoint, when Edit Route is clicked should take user back to field to end start and end pois, or add more pois
  // ie, return user to DirectionsSearchControls
  const onEditRouteClick = () => {
    app.bus.send('layers/hideMultiple', [DIRECTIONS_RESULT_VIEW_ID_DESKTOP, DIRECTIONS_RESULT_VIEW_ID_MOBILE])
    app.bus.send('navigation/show', { reset: false })
  }

  // When ending route clear the widget state (no pois selected), remove widget layers, and center
  // map on current user location
  const onEndRouteClick = () => {
    app.bus.send('headerOnline/show')
    app.bus.send('layers/hideMultiple', [DIRECTIONS_RESULT_VIEW_ID_DESKTOP, DIRECTIONS_RESULT_VIEW_ID_MOBILE])
    widgetState.update({ ...getDefaultState() })
  }

  const locale = app.i18n().language

  app.bus.send('layers/register', {
    id: DIRECTIONS_RESULT_VIEW_ID_DESKTOP,
    widget: () => <DirectionsResult
      bus={app.bus}
      onNextStepClick={stepForward}
      onPrevStepClick={stepBackward}
      onStepClickHandler={stepTo}
      toggleIsNavigating={toggleIsNavigating}
      toggleNavigationHeader={toggleNavigationHeader}
      widgetState={widgetState}
      isDesktop={() => app.env.isDesktop()}
      Loading={Loading}
      T={app.gt()}
      onEditRouteClick={onEditRouteClick}
      onEndRouteClick={onEndRouteClick}
      locale={locale}
    />,
    layoutId: 'content',
    layoutName: 'fullscreen',
    widgetType: widgetEnum.Desktop
  })

  app.bus.send('layers/register', {
    id: DIRECTIONS_RESULT_VIEW_ID_MOBILE,
    widget: () => <DirectionsResult
      bus={app.bus}
      onNextStepClick={stepForward}
      onPrevStepClick={stepBackward}
      onStepClickHandler={stepTo}
      toggleIsNavigating={toggleIsNavigating}
      toggleNavigationHeader={toggleNavigationHeader}
      widgetState={widgetState}
      isDesktop={app.env.isDesktop}
      Loading={Loading}
      T={app.gt()}
      onEditRouteClick={onEditRouteClick}
      onEndRouteClick={onEndRouteClick}
      locale={locale}
    />,
    layoutId: 'bottomBar',
    layoutName: 'fullscreen',
    widgetType: widgetEnum.Mobile
  })

  app.bus.send('layers/register', {
    id: LANE_PICKER_VIEW_ID,
    widget: () => <LanePickerView
      widgetState={widgetState}
      isDesktop={() => app.env.isDesktop()}
      T={app.gt()}
      onSubmitClick={submitLanePickerModal}
      onModalDismiss={handleModalDismissWithDefaultOption}
    />,
    layoutId: 'modalWindow',
    layoutName: 'fullscreen'
  })

  const handleModalDismissWithDefaultOption = () => {
    const currentState = widgetState.getState()

    const setDefaultOption = (lanes) => {
      return lanes.map((lane) => ({ ...lane, default: lane.id === 'general' }))
    }

    widgetState.update({
      ...currentState,
      securityLanes: setDefaultOption(currentState.securityLanes),
      immigrationLanes: setDefaultOption(currentState.immigrationLanes)
    })

    submitLanePickerModal()
  }

  return {
    ANIMATION_CENTER_RADIUS,
    animateToPoint,
    compareMultiLegRoutes,
    displayDirections,
    displayNavlines,
    displayRoutes,
    getInputNewData,
    parseRoute,
    setSearchInputsFromEndpointsIds,
    stepBackward,
    stepForward,
    stepTo,
    submitLanePickerModal,
    toggleIsNavigating
  }
}
